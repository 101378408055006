@import url(./navbar.css);
@import url(./left.css);
@import url(./right.css);

html, body {
  background-color: #4280d7;
  color: var(--text-secondary);
  margin: 0;
  font-family: "Helvetica Neue", Helvetica;
  font-weight: bold;
  --text-secondary: hsla(225, 15%, 40%, 1);
  --main-color: hsla(215, 100%, 65%, 1);
  --secondary-color: hsla(30, 100%, 55%, 1);
  --tertiary-color: hsla(163, 85%, 40%, 1);
  --lighter-color: hsla(215, 100%, 75%, 1);
  --lightgray: hsla(0, 0%, 94%, 1);
  --box-shadow: 0 0 0 0.25rem hsla(215deg, 100%, 65%, 35%);
}

input, div.text-editor {
  transition: 0.2s;
}

input:focus:focus:focus {
  box-shadow: 0 0 0 0.25rem hsla(0, 100%, 100%, 0.25);
  outline: none;
  color: var(--text-secondary);
}

main {
  display: flex;
  top: 3rem;
  position: absolute;
  height: calc(100vh - 3rem);
  width: 100vw;
  gap: 0.5rem;
  background-color: hsla(215, 100%, 95%, 1);
  z-index: -10;
}

* {
  box-sizing: border-box;
}

.flex-grow {
  flex-grow: 1;
}

.no-bold {
  font-weight: normal;
}

.flex {
  display: flex;
}

::selection {
  background-color: rgba(77, 151, 255, 0.25);
}