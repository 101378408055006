nav {
  background-color: var(--main-color);
  color: #fff;
  height: 3rem;
  font-size: .75rem;
}

nav a.option {
  color: #fff;
  padding: 1.125rem 1em;
  text-decoration: none;
  display: inline-block;
  position: relative;
  top: calc(50% - 2.5em);
}

nav a.option:is(:hover, :focus) {
  background-color: #00000026;
  outline: none;
}

nav div.divider {
  height: 2.125rem;
  border-right: 1px dashed #00000026;
  margin: 0 .5rem;
  display: inline-block;
  position: relative;
  top: calc(50% - 1.0625rem);
}

nav input.title {
  color: #fff;
  height: 2rem;
  background-color: #ffffff40;
  border: 1px dashed #00000026;
  border-radius: .25rem;
  margin-left: .5rem;
  padding: 0 .5rem;
  font-size: .8rem;
  font-weight: bold;
  transition: all .2s;
  position: relative;
  bottom: calc(.75rem - 50% + 2px);
  transform: translateY(-50%);
}

nav input.title:focus {
  background-color: #fff;
  border: 1px dashed #0000;
}

nav button {
  height: 2rem;
  cursor: pointer;
  border: none;
  border-radius: .25rem;
  padding: 0 .75rem;
  font-size: .75rem;
  font-weight: bold;
  position: relative;
}

nav button.share {
  background-color: var(--secondary-color);
  color: #fff;
  margin-left: .5rem;
  bottom: calc(.75rem - 50% + 1px);
  transform: translateY(-50%);
}

nav button.feedback {
  color: var(--main-color);
  float: right;
  background-color: #fff;
  align-items: center;
  margin-right: .5rem;
  display: flex;
  position: relative;
  top: .5rem;
}

nav a.feedback-wrapper {
  display: contents;
}

nav button.feedback img {
  height: 2em;
  margin-right: .5rem;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

div.left {
  flex-grow: 1;
  font-weight: normal;
}

div.editor {
  height: calc(100vh - 6.25rem);
  flex-grow: 1;
  display: flex;
  position: relative;
}

div.editor div.files {
  width: 310px;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-right: none;
}

div.editor div.text-editor {
  cursor: text;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 0 .5rem .5rem 0;
  outline: none;
  flex-grow: 1;
  padding: 1rem;
  font-family: Source Code Pro, monospace;
  transition: all .2s;
  position: relative;
  overflow: hidden;
}

div.editor div.contenteditable:focus ~ div.text-editor {
  box-shadow: var(--box-shadow);
  border-color: #4c97ff;
}

div.editor div.contenteditable {
  width: 0;
  height: 0;
  border: none;
  outline: none;
  overflow: hidden;
}

div.editor div.contenteditable div {
  display: contents;
}

div.text-editor div.caret {
  height: 1.25em;
  border-right: 1px solid #000;
  display: inline-block;
  position: absolute;
}

div.text-editor pre.text {
  font-family: inherit;
  font-size: inherit;
  color: #000;
  counter-reset: line;
  z-index: 1;
  border-radius: 0 .5rem .5rem 0;
  margin: 0;
  padding: 1rem 0;
  position: absolute;
  inset: 0;
  overflow: auto;
}

div.text-editor div.caret.animation {
  animation: blink 1s step-start infinite backwards;
}

div.text-editor pre.text div.line {
  height: 1.25em;
  counter-increment: line;
  padding: 1px 1rem;
}

div.text-editor pre.text div.line:before {
  content: counter(line);
  color: gray;
  margin-right: 1rem;
}

div.text-editor pre.text div.line.active {
  background-color: #0000000d;
}

div.editor div.contenteditable:not(:focus) ~ div.text-editor div.caret {
  display: none;
}

ul.tabs li.tab {
  height: 80%;
  background-color: #d9e3f2;
  border: 1px solid #d3d3d3;
  border-bottom: none;
  border-radius: 1rem 1rem 0 0;
  padding: .125rem 1.25rem 0;
  display: inline-block;
  position: relative;
  top: 20%;
}

ul.tabs li.tab.active {
  color: #4c97ff;
  height: 90%;
  background-color: #fff;
  top: 10%;
}

ul.tabs {
  height: 2.75rem;
  margin: 0;
  padding: 0;
  font-size: .75rem;
}

ul.tabs li.tab img {
  height: 1.375rem;
  -webkit-user-select: none;
  user-select: none;
  margin-right: .125rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

div.editor div.files {
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  counter-reset: padding;
  padding: 1rem;
  font-size: .9rem;
  font-weight: normal;
  line-height: 2;
  position: relative;
}

div.editor div.files > :not(span.project-name) {
  opacity: .6;
}

div.editor div.files ul.folder {
  cursor: default;
  counter-increment: padding;
  padding: 0 1rem;
  list-style-type: none;
  position: relative;
}

div.editor div.files li.folder-wrapper[data-closed] *, span.project-name[data-closed] ~ ul.root-folder {
  display: none;
}

:is(div.editor div.files li.folder-wrapper[data-closed], span.project-name[data-closed]):before {
  transform: rotate(-90deg)translate(.25em, -.25em);
}

div.editor div.files li.folder-wrapper, div.editor div.files span.project-name {
  cursor: pointer;
}

:is(div.editor div.files li.folder-wrapper, div.editor div.files span.project-name):before {
  content: "";
  height: .5em;
  width: .5em;
  transform: rotate(0) translate(0, 0);
  background-image: url("folder-icon.028b34a3.svg");
  background-repeat: no-repeat;
  background-size: contain;
  padding-right: .5em;
  transition: transform .1s;
  display: inline-block;
}

div.file-options a.new-file {
  margin-right: .5rem;
}

div.file-options a img {
  height: 1.5rem;
}

div.file-options {
  position: absolute;
  bottom: .5rem;
  right: 1rem;
}

:is(.file-drag.folder li:last-child, .file-drag > ul.folder li:last-child, li.file.file-drag:not(.folder-wrapper)) {
  box-shadow: 0 1px var(--main-color);
}

div.files li.file {
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

div.right {
  width: calc(480px + .5rem);
  height: 100%;
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

div.player-wrapper {
  height: calc(2.75rem + 360px);
}

div.player-stage {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: .5rem;
  overflow: hidden;
}

div.player-controls {
  height: 2.75rem;
}

div.player-controls.player-controls span {
  opacity: 1;
  position: relative;
  top: calc(1.375rem - 16px);
}

div.player-controls.player-controls span.player-pause {
  display: none;
}

div.player-controls span.player-fullscreen-btn {
  background-color: #fff;
  background-position: -129px -1px;
  border: 1px solid #d3d3d3;
  border-radius: .25rem;
  right: .5em;
}

div.player-controls span.player-flag {
  background-position: -1px 1px;
}

div.player-controls span.player-stop {
  background-position: -95.5px -.5px;
}

div.player-controls span:is(.player-flag, .player-stop, .player-pause):hover {
  background-color: #4c97ff26;
  border-radius: .25rem;
}

div.right div.sprite-pane {
  width: 480px;
  flex-grow: 1;
  gap: .5rem;
  font-size: .625rem;
  display: flex;
}

div.sprite-pane div.stage-selector {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: .5rem .5rem 0 0;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 72px;
  display: flex;
}

div.sprite-pane div.sprite-selector {
  border: 1px solid #d3d3d3;
  border-radius: .5rem .5rem 0 0;
  flex-grow: 1;
  position: relative;
}

div.sprite-pane div.stage-selector div.stage-header {
  border-bottom: 1px solid #d3d3d3;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: calc(2.75rem - 2px);
  justify-content: center;
  align-items: center;
  display: flex;
}

.sprite-pane-header {
  font-size: .625rem;
}

div.sprite-info {
  border-radius: inherit;
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
  padding: .75rem;
}

div.sprite-info input {
  height: 2rem;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  border: 1px solid #d3d3d3;
  border-radius: 1rem;
  padding: 0 .75rem;
  transition: all .2s;
}

div.sprite-info input:focus:focus:focus {
  box-shadow: var(--box-shadow);
  border-color: #4c97ff;
}

div.sprite-info input.sprite-name {
  width: 8rem;
}

div.sprite-pane div.sprite-option {
  display: inline-block;
}

div.sprite-info div.sprite-info-row {
  align-items: center;
  gap: 1rem;
  margin-bottom: .5rem;
  display: flex;
}

div.sprite-info div.sprite-info-row:last-child {
  margin-bottom: 0;
}

div.sprite-info div.sprite-option {
  align-items: center;
  gap: .5rem;
  display: inline-flex;
}

div.sprite-info div.sprite-option.flex input {
  width: 0;
}

div.sprite-info div.sprite-option input:is(.sprite-x, .sprite-y) {
  text-align: center;
}

.sprite-pane .sprite-row {
  height: calc(20% - .5rem);
}

.sprite-pane .sprite {
  width: calc(20% - .5rem);
  max-width: 6rem;
  min-width: 4rem;
  min-height: 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 2px solid #d3d3d3;
  border-radius: .5rem;
  margin: .25rem;
  position: relative;
}

.sprite-pane .sprites {
  flex-wrap: wrap;
  padding: .25rem;
}

.sprite-pane .sprite.active {
  border-color: var(--main-color);
  box-shadow: var(--box-shadow);
  background-color: #fff;
}

.sprite-pane .sprite:hover {
  border-color: var(--main-color);
}

.sprite-pane .sprite .sprite-name {
  width: 100%;
  text-align: center;
  padding: .25rem;
  font-weight: normal;
  position: absolute;
  bottom: 0;
}

.sprite-pane .sprite.active .sprite-name {
  color: #fff;
  background-color: var(--main-color);
}

button.new-sprite img {
  width: 1.75rem;
  height: 1.75rem;
}

button.new-sprite {
  width: 2.75rem;
  height: 2.75rem;
  background-color: var(--main-color);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  border: none;
  border-radius: 1.375rem;
  padding: .5rem;
  transition: transform .5s, box-shadow .5s;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

button.new-sprite:hover {
  background-color: var(--tertiary-color);
  transform: scale(1.1);
  box-shadow: 0 0 0 .375rem #4c97ff59;
}

html, body {
  color: var(--text-secondary);
  --text-secondary: #575e75;
  --main-color: #4c97ff;
  --secondary-color: #ff8c1a;
  --tertiary-color: #0fbd8c;
  --lighter-color: #80b5ff;
  --lightgray: #f0f0f0;
  --box-shadow: 0 0 0 .25rem #4c97ff59;
  background-color: #4280d7;
  margin: 0;
  font-family: Helvetica Neue, Helvetica;
  font-weight: bold;
}

input, div.text-editor {
  transition: all .2s;
}

input:focus:focus:focus {
  color: var(--text-secondary);
  outline: none;
  box-shadow: 0 0 0 .25rem #ffffff40;
}

main {
  height: calc(100vh - 3rem);
  width: 100vw;
  z-index: -10;
  background-color: #e6f0ff;
  gap: .5rem;
  display: flex;
  position: absolute;
  top: 3rem;
}

* {
  box-sizing: border-box;
}

.flex-grow {
  flex-grow: 1;
}

.no-bold {
  font-weight: normal;
}

.flex {
  display: flex;
}

::selection {
  background-color: #4d97ff40;
}

/*# sourceMappingURL=index.becb4d4b.css.map */
