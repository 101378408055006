@keyframes blink {
  50% {
    opacity: 0;
  }
}

div.left {
  flex-grow: 1;
  font-weight: normal;
}

div.editor {
  flex-grow: 1;
  display: flex;
  height: calc(100vh - 6.25rem);
  position: relative;
}

div.editor div.files {
  border: 1px solid lightgray;
  border-right: none;
  width: 310px;
  background-color: white;
}

div.editor div.text-editor {
  font-family: "Source Code Pro", monospace;
  border-radius: 0 0.5rem 0.5rem 0;
  border: 1px solid lightgray;
  flex-grow: 1;
  outline: none;
  cursor: text;
  transition: 0.2s;
  background-color: white;
  position: relative;
  padding: 1rem;
  overflow: hidden;
}

div.editor div.contenteditable:focus ~ div.text-editor {
  box-shadow: var(--box-shadow);
  border-color: hsla(215, 100%, 65%, 1);
}

div.editor div.contenteditable {
  width: 0;
  height: 0;
  border: none;
  outline: none;
  overflow: hidden;
}

div.editor div.contenteditable div {
  display: contents;
}

div.text-editor div.caret {
  border-right: 1px solid black;
  display: inline-block;
  height: 1.25em;
  position: absolute;
}

div.text-editor pre.text {
  padding: 1rem 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  border-radius: 0 0.5rem 0.5rem 0;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  overflow: auto;
  color: black;
  counter-reset: line;
  z-index: 1;
}

div.text-editor div.caret.animation {
  animation: blink 1s step-start 0s infinite backwards;
}

div.text-editor pre.text div.line {
  padding: 1px 1rem;
  height: 1.25em;
  counter-increment: line;
}

div.text-editor pre.text div.line::before {
  content: counter(line);
  margin-right: 1rem;
  color: gray;
}

div.text-editor pre.text div.line.active {
  background-color: hsla(0, 0%, 0%, 0.05);
}

div.editor div.contenteditable:not(:focus) ~ div.text-editor div.caret {
  display: none;
}

ul.tabs li.tab {
  height: 80%;
  position: relative;
  top: 20%;
  border-radius: 1rem 1rem 0 0;
  background-color: hsla(215, 50%, 90%, 1);
  border: 1px solid lightgray;
  padding: 0.125rem 1.25rem 0;
  display: inline-block;
  border-bottom: none;
}

ul.tabs li.tab.active {
  color: hsla(215, 100%, 65%, 1);
  height: 90%;
  top: 10%;
  background-color: white;
}

ul.tabs {
  margin: 0;
  height: 2.75rem;
  padding: 0;
  font-size: 0.75rem;
}

ul.tabs li.tab img {
  height: 1.375rem;
  margin-right: 0.125rem;
  user-select: none;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}

div.editor div.files {
  padding: 1rem;
  font-weight: normal;
  color: black;
  line-height: 2;
  font-size: 0.9rem;
  user-select: none;
  position: relative;
  counter-reset: padding;
}

div.editor div.files > *:not(span.project-name) {
  opacity: 0.6;
}

div.editor div.files ul.folder {
  padding: 0 1rem;
  position: relative;
  list-style-type: none;
  cursor: default;
  counter-increment: padding;
}

div.editor div.files li.folder-wrapper[data-closed] *, span.project-name[data-closed] ~ ul.root-folder {
  display: none;
}

:is(div.editor div.files li.folder-wrapper[data-closed], span.project-name[data-closed])::before {
  transform: rotate(-90deg) translate(0.25em, -0.25em) /* x is y and y is x */
}

div.editor div.files li.folder-wrapper, div.editor div.files span.project-name {
  cursor: pointer;
}

:is(div.editor div.files li.folder-wrapper, div.editor div.files span.project-name)::before {
  content: "";
  background-image: url("../assets/folder-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 0.5em;
  width: 0.5em;
  padding-right: 0.5em;
  transition: 0.1s;
  transition-property: transform;
  transform: rotate(0) translate(0, 0);
}

div.file-options a.new-file {
  margin-right: 0.5rem;
}

div.file-options a img {
  height: 1.5rem;
}

div.file-options {
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
}

:is(.file-drag.folder li:last-child, .file-drag > ul.folder li:last-child, li.file.file-drag:not(.folder-wrapper)) {
  box-shadow: 0 1px var(--main-color); /* feels kind of hacky but it works */
}

div.files li.file {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/*li.file:is([data-type^="image/"])::before {
  content: "";
  background-image: url("../assets/file-icons/image.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 0.75em;
  padding-right: 1.5em;
  filter: invert(1);
}*/