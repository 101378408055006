div.right {
  width: calc(480px + 0.5rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

div.player-wrapper {
  height: calc(2.75rem + 360px);
}

div.player-stage {
  border-radius: 0.5rem;
  border: 1px solid lightgray;
  overflow: hidden;
  background-color: white;
}

div.player-controls {
  height: 2.75rem;
}

div.player-controls.player-controls span {
  top: calc(1.375rem - 16px); /* not magic number: 16px is half the image size */
  position: relative;
  opacity: 1;
}

div.player-controls.player-controls span.player-pause {
  display: none;
}

div.player-controls span.player-fullscreen-btn {
  border-radius: 0.25rem;
  border: 1px solid lightgray;
  background-color: white;
  background-position: -129px -1px;
  right: 0.5em;
}

div.player-controls span.player-flag {
  background-position: -1px 1px;
}

div.player-controls span.player-stop {
  background-position: -95.5px -0.5px;
}

div.player-controls span:is(.player-flag, .player-stop, .player-pause):hover {
  background-color: hsla(215, 100%, 65%, 0.15);
  border-radius: 0.25rem;
}

div.right div.sprite-pane {
  display: flex;
  flex-grow: 1;
  width: 480px;
  gap: 0.5rem;
  font-size: 0.625rem;
}

div.sprite-pane div.stage-selector {
  flex-basis: 72px;
  flex-shrink: 0;
  background-color: white;
  border-radius: 0.5rem 0.5rem 0 0;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
}

div.sprite-pane div.sprite-selector {
  flex-grow: 1;
  border-radius: 0.5rem 0.5rem 0 0;
  border: 1px solid lightgray;
  position: relative;
}

div.sprite-pane div.stage-selector div.stage-header {
  flex-basis: calc(2.75rem - 2px);
  flex-shrink: 0;
  border-bottom: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sprite-pane-header {
  font-size: 0.625rem;
}

div.sprite-info {
  padding: 0.75rem;
  border-bottom: 1px solid lightgray;
  background-color: white;
  border-radius: inherit;
}

div.sprite-info input {
  height: 2rem;
  border-radius: 1rem;
  border: 1px solid lightgray;
  padding: 0 0.75rem;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  transition: 0.2s;
  color: inherit;
}

div.sprite-info input:focus:focus:focus {
  box-shadow: var(--box-shadow);
  border-color: hsla(215, 100%, 65%, 1);
}

div.sprite-info input.sprite-name {
  width: 8rem;
}

div.sprite-pane div.sprite-option {
  display: inline-block;
}

div.sprite-info div.sprite-info-row {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.5rem;
}

div.sprite-info div.sprite-info-row:last-child {
  margin-bottom: 0;
}

div.sprite-info div.sprite-option {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

div.sprite-info div.sprite-option.flex input {
  width: 0px;
}

div.sprite-info div.sprite-option input:is(.sprite-x, .sprite-y) {
  text-align: center;
}

.sprite-pane .sprite-row {
  height: calc((100% / 5 ) - 0.5rem);
}

.sprite-pane .sprite {
  width: calc((100% / 5 ) - 0.5rem);
  max-width: 6rem;
  min-width: 4rem;
  min-height: 4rem;
  border-radius: 0.5rem;
  border: 2px solid lightgray;
  margin: 0.25rem;
  position: relative;
  cursor: pointer; /* for whatever reason */
  user-select: none;
}

.sprite-pane .sprites {
  padding: 0.25rem;
  flex-wrap: wrap;
}

.sprite-pane .sprite.active {
  background-color: white;
  border-color: var(--main-color);
  box-shadow: var(--box-shadow);
}

.sprite-pane .sprite:hover {
  border-color: var(--main-color);
}

.sprite-pane .sprite .sprite-name {
  padding: 0.25rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-weight: normal;
}

.sprite-pane .sprite.active .sprite-name {
  color: white;
  background-color: var(--main-color);
}

button.new-sprite img {
  width: 1.75rem;
  height: 1.75rem;
}

button.new-sprite {
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.5rem;
  background-color: var(--main-color);
  border-radius: 1.375rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  transition: 0.5s;
  transition-property: transform, box-shadow;
  border: none;
}

button.new-sprite:hover {
  transform: scale(1.1);
  box-shadow: 0 0 0 0.375rem hsl(215deg 100% 65% / 35%);
  background-color: var(--tertiary-color);
}